import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import 'hammerjs';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (!isDevMode()) {
  Sentry.init({
    dsn: 'https://b6cad1dc6b570a8eb81df6f30d33c5ff@o263138.ingest.sentry.io/4506268596371456',
    integrations: [],
    tracesSampleRate: 0,
    autoSessionTracking: false,
    sendClientReports: false,
  });
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
