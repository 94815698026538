import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Asset,
  ContentResponse,
  ICatalogBySeoUrl,
  ICatalogFilter,
  ICatalogMini,
  IPageBySeoUrl,
  IProductBySeoUrl,
  IProductMini,
  RelatedProductsResponse,
  SearchProductResponse,
} from './icebreak-webshop.interface';
import { addLanguageKeyInUrl } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class WebshopService {
  private readonly http = inject(HttpClient);
  private readonly translateService = inject(TranslateService);
  private readonly contentCache: {
    [key: string]: ContentResponse;
  } = {
    home: {
      type: 'page',
      seourl: 'home',
      content: {
        title: 'Eriksen Randers A/S | Førende gulvgrossist med et stort & bredt sortiment',
        metaDescription:
          'Velkommen til Eriksen Randers A/S - din førende gulvgrossist i Danmark! Udforsk vores udvalg af kvalitetsvare & find svar på levering, afhentning & kundeinformation.',
        contentType: 'stack',
        items: [
          {
            contentType: 'carousel',
            theme: 'banner',
            items: [
              {
                header: 'Nyhed hos Eriksen Randers',
                description: 'Vi forhandler nu WOCA produkter.',
                backgroundImage: '/assets/images/cover-banner/Woca-products-v2.png',
                link: {
                  label: 'Se vores sortiment her',
                  url: '/woca',
                },
              },
              {
                header: 'Nyhed hos Eriksen Randers',
                description: 'Nu kan du lease din næste FG FLOORTEC maskine hos Eriksen Randers, ganske enkelt.',
                backgroundImage: '/assets/images/cover-banner/Leasing-fg.png',
                link: {
                  label: 'Se leasing her',
                  url: '/leasing',
                },
              },
              {
                header: 'Nyhed hos Eriksen Randers',
                description: 'Nu kan du lease din næste Wolf maskine hos Eriksen Randers, ganske enkelt.',
                backgroundImage: '/assets/images/cover-banner/Leasing-wolff.png',
                link: {
                  label: 'Se leasing her',
                  url: '/leasing',
                },
              },
            ],
          },
          {
            contentType: 'carousel',
            theme: 'logo-slider',
          },
          {
            contentType: 'widget',
            data: {
              type: 'popular-products',
              useMaxWidth: true,
            },
          },
          {
            contentType: 'widget',
            data: {
              type: 'login-banner',
            },
          },
          {
            contentType: 'widget',
            data: {
              type: 'popular-categories',
              useMaxWidth: true,
            },
          },
          {
            contentType: 'old-price-sheet',
          },
          {
            contentType: 'widget',
            data: {
              type: 'faq',
              useMaxWidth: true,
              category: 'dfsd',
            },
          },
        ],
      },
    },
    baeredygtighed: {
      type: 'page',
      seourl: 'home',
      content: {
        title: 'Bæredygtighed',
        metaDescription: 'Bæredygtighed',
        contentType: 'stack',
        items: [
          {
            contentType: 'banner2',
            header: 'Bæredygtighed',
            description: 'Bæredygtighed',
            backgroundImage: '/assets/images/single-banner.png',
          },
          {
            contentType: 'markdown',
            useMaxWidth: true,
            content: `## Bæredygtighed`,
          },
          {
            contentType: 'widget',
            data: {
              type: 'login-banner',
            },
          },
        ],
      },
    },
    'kunde-konto': {
      type: 'page',
      seourl: 'kunde-konto',
      content: {
        title: 'Kunde konto - Erisken Randers | Eksklusive fordele & service',
        metaDescription:
          'Opret en kunde konto hos Eriksen Randers og oplev fordelene ved et tæt samarbejde. Få adgang til eksklusive rabatter, personlig service, enkel online bestilling, og meget mere. Log ind eller anmod om en konto i dag.',
        contentType: 'stack',
        items: [
          {
            contentType: 'banner2',
            header: 'Kunde konto',
            description: 'En del af Eriksen',
            backgroundImage: '/assets/images/single-banner.png',
          },
          {
            contentType: 'markdown',
            useMaxWidth: true,
            content: `## Eriksen kunde konto - Et godt samarbejde!
Velkommen til Eriksen Randers - hvor en kunde konto ikke blot er en konto, men nøglen til et tæt samarbejde. Din personlige kundekonto er mere end blot en måde at afgive ordrer; det er porten til en verden af eksklusive fordele og førsteklasses service. Vi stræber efter at gøre din oplevelse hos Eriksen unik og skræddersyet til dine behov. Fra særlige tilbud til personlige anbefalinger er din kundekonto gatewayen til det bedste, vi har at tilbyde. Oplev forskellen ved at være en del af Eriksen-familien.

## Fordele ved en Eriksen kunde konto

Med din Eriksen Kunde konto bliver hver bestilling mere end bare en transaktion, det bliver starten på et personligt samarbejde hvor du er i fokus. Gennem bl.a. online bestillig, adgang til tidligere køb, og en personlig salgskonsulent stræber vi efter at tilbyde mere end blot produkter, vi bestræber os på at udføre den bedste service og finde de vare der passer til din opgave.

* Optjen eksklusive rabatter ved at handle regelmæssigt. Jo mere du køber, desto bedre bliver din rabat.
* Enkel online bestilling af dine ordrer, for en problemfri shoppingoplevelse.
* Oversigt over dine tidligere ordrer, så du nemt kan spore din købshistorik.
* Identifikation af din dedikerede salgskonsulent for en personlig og skræddersyet service.
* Eksklusiv adgang til særlige tilbud og kampagner, der kun er tilgængelige for vores kundekonto-medlemmer.
* Praktisk mulighed for at gemme dine yndlingsprodukter til hurtig og nem adgang i fremtiden.`,
          },
          {
            contentType: 'widget',
            data: {
              type: 'login-banner',
            },
          },
        ],
      },
    },
    'om-eriksen': {
      type: 'page',
      seourl: 'home',
      content: {
        title: 'Om Eriksen',
        metaDescription: 'Om Eriksen',
        contentType: 'stack',
        items: [
          {
            contentType: 'banner2',
            header: 'Om Eriksen',
            description:
              'Vi lagerføre markedets mest efterspurgte linoleum-design fra Forbo og DLW, der er de førende producenter af linoleum i verden.',
            backgroundImage: '/assets/images/single-banner.png',
          },
          {
            contentType: 'markdown',
            useMaxWidth: true,
            content: `## Om Eriksen`,
          },
          {
            contentType: 'widget',
            data: {
              type: 'login-banner',
            },
          },
        ],
      },
    },
    kontakt: {
      type: 'page',
      seourl: 'home',
      content: {
        title: 'Kontakt Eriksen Randers | Adresse, Åbningstider & Ansatte',
        metaDescription:
          'Kontakt Eriksen Randers for spørgsmål og support. Find vores adresse, åbningstider, og mød vores team. Udfyld vores kontaktformular eller se medarbejdernes kontaktinformationer.',
        contentType: 'stack',
        items: [
          {
            contentType: 'banner2',
            header: 'Kontakt os i dag',
            description: 'Vi er altid klar til at hjælpe',
            backgroundImage: '/assets/images/image 277.png',
          },
          {
            contentType: 'widget',
            data: {
              type: 'contact-form',
            },
          },
        ],
      },
    },
    kariere: {
      type: 'page',
      seourl: 'home',
      content: {
        title: 'Karriere i Eriksen',
        contentType: 'stack',
        items: [
          {
            contentType: 'banner2',
            header: 'Karriere i Eriksen',
            description: 'Bliv en del af Eriksen familien',
            backgroundImage: '/assets/images/single-banner.png',
          },
          {
            contentType: 'markdown',
            useMaxWidth: true,
            content: `Velkommen til Eriksen Randers, hvor vi tror på at skabe en arbejdsplads, der dyrker vækst, teamwork og passion for det, vi gør. Hos os er medarbejderne hjørnestenen i vores succes. Vi tilbyder et inspirerende arbejdsmiljø og et dedikeret team, der arbejder sammen mod fælles mål. Hos Eriksen Randers handler det ikke bare om job, det handler om at være en del af en stærk og engageret virksomhedskultur.`,
          },
          {
            contentType: 'duel-panel',
            direction: 'left',
            header: 'Ledige stillinger',
            paragraph: `Hos Eriksen vil du hurtigt opleve at, arbejdsglæde, viden, nysgærighed og ansvarlighed er nøgleord vi går meget op i.

Se vores ledige stillinger her, og hvis ikke der er din drømme stilling, så tøv ikke med at sende os en uopfordret ansøgning.`,
            link: {
              url: '/',
              label: 'Søg ledige stillinger',
            },
            image: {
              link: '/assets/images/single-banner.png',
              alt: 'Alt',
            },
          },
          {
            contentType: 'duel-panel',
            direction: 'right',
            header: 'Ledige stillinger',
            paragraph: `Hos Eriksen vil du hurtigt opleve at, arbejdsglæde, viden, nysgærighed og ansvarlighed er nøgleord vi går meget op i.

Se vores ledige stillinger her, og hvis ikke der er din drømme stilling, så tøv ikke med at sende os en uopfordret ansøgning.`,
            link: {
              url: '/',
              label: 'Søg ledige stillinger',
            },
            image: {
              link: '/assets/images/single-banner.png',
              alt: 'Alt',
            },
          },
          {
            contentType: 'duel-panel',
            direction: 'left',
            header: 'Ledige stillinger',
            paragraph: `Hos Eriksen vil du hurtigt opleve at, arbejdsglæde, viden, nysgærighed og ansvarlighed er nøgleord vi går meget op i.

Se vores ledige stillinger her, og hvis ikke der er din drømme stilling, så tøv ikke med at sende os en uopfordret ansøgning.`,
            link: {
              url: '/',
              label: 'Søg ledige stillinger',
            },
            image: {
              link: '/assets/images/single-banner.png',
              alt: 'Alt',
            },
          },
          {
            contentType: 'duel-panel',
            direction: 'right',
            header: 'Ledige stillinger',
            paragraph: `Hos Eriksen vil du hurtigt opleve at, arbejdsglæde, viden, nysgærighed og ansvarlighed er nøgleord vi går meget op i.

Se vores ledige stillinger her, og hvis ikke der er din drømme stilling, så tøv ikke med at sende os en uopfordret ansøgning.`,
            link: {
              url: '/',
              label: 'Søg ledige stillinger',
            },
            image: {
              link: '/assets/images/single-banner.png',
              alt: 'Alt',
            },
          },
        ],
      },
    },
    '404': {
      type: 'page',
      seourl: 'home',
      content: {
        title: 'Siden blev ikke fundet',
        contentType: 'stack',
        items: [
          {
            contentType: 'banner2',
            header: 'Siden blev ikke fundet',
            description: 'Vi kan ikke finde den side du leder efter.',
            backgroundImage: '/assets/images/single-banner.png',
          },
        ],
      },
    },
  };

  private getContent(
    key: string,
    queryParam?: { [key: string]: string | number },
    ignoreCache?: boolean
  ): Observable<ContentResponse | undefined> {
    if (!ignoreCache && this.contentCache[key]) {
      return of(this.contentCache[key]);
    }
    return this.http
      .get<ContentResponse | undefined>(`@api/cms/prd/getContentBySeoUrl/${key}`, {
        params: new HttpParams().appendAll(queryParam || {}),
      })
      .pipe(
        catchError(() => {
          console.error(`Requested key resulted in error: ${key}`);
          return of(undefined);
        }),
        map(res => {
          if (!res) {
            console.error(`Requested key not found: ${key}`);
            return undefined;
          }

          // Service returns emtpy object if seourl is not available
          if (typeof res === 'object' && !Object.keys(res).length) {
            return undefined;
          }

          return res;
        })
      );
  }
  private _getContentMetaByKey(key: string): Observable<ContentResponse | undefined> {
    return of(undefined).pipe(
      switchMap(() => {
        if (this.contentCache[key]) {
          return of(this.contentCache[key]);
        }
        return this.http.get<ContentResponse | undefined>(`@api/cms/prd/getContentMetaBySeoUrl/${key}`).pipe(
          catchError(() => {
            console.error(`Requested key resulted in error: ${key}`);
            return of(undefined);
          }),
          map(res => {
            if (!res) {
              console.error(`Requested key not found: ${key}`);
              return undefined;
            }

            // Service returns emtpy object if seourl is not available
            if (typeof res === 'object' && !Object.keys(res).length) {
              return undefined;
            }

            return res;
          })
        );
      })
    );
  }
  public getContentMetaByKey(key: string): Observable<
    | {
        type: 'catalog' | 'product' | 'page';
        seourl: string;
        title: string;
        metaTitle?: string;
        metaDescription?: string;
        assets: Asset[];
      }
    | undefined
  > {
    return this._getContentMetaByKey(key).pipe(
      map(res => {
        if (!res) {
          return res;
        }
        if (res.type === 'catalog') {
          return {
            type: res.type,
            seourl: res.seourl,
            title: res.content.title,
            metaTitle: res.content.metaTitle,
            metaDescription: res.content.metaDescription,
            assets: res.content.imageUrl
              ? [
                  {
                    type: 'image' as const,
                    alt: res.content.title,
                    link: res.content.imageUrl,
                  },
                ]
              : [],
          };
        }
        if (res.type === 'product') {
          return {
            type: res.type,
            seourl: res.seourl,
            title: res.content.title,
            metaTitle: res.content.metaTitle,
            metaDescription: res.content.metaDescription,
            assets: res.content.images.map(i => {
              return {
                type: 'image' as const,
                alt: i.title,
                link: i.url,
              };
            }),
          };
        }
        if (res.type === 'page') {
          if (res.content.contentType !== 'stack') {
            console.error(`Invalid page type ${res.content.contentType}`); // A page must be a stack, even if it has only 1 stack item
            return undefined;
          }
          return {
            type: res.type,
            seourl: res.seourl,
            title: res.content.title,
            metaTitle: res.content.metaTitle,
            metaDescription: res.content.metaDescription,
            assets: [],
          };
        }

        console.error(`Unknown type`);
        return undefined;
      })
    );
  }
  private getFilterByKey(key: string): Observable<ICatalogFilter[]> {
    return this.http
      .get<
        {
          property: string; //key
          label: string; // label
          sequence: number; // sequence
          values: [
            {
              value: string; // key and label
              label: string; // key and label
              number: number; // Total products count
            },
          ];
        }[]
      >(`@api/cms/prd/listProductCatalogFiltersBySeoUrl/${key}`)
      .pipe(
        map(res => {
          return [...res]
            .sort((a, b) => a.sequence - b.sequence)
            .map(i => {
              return {
                key: i.property,
                label: i.label,
                values: i.values.map(v => {
                  return {
                    value: v.value,
                    label: v.label,
                    productsCount: v.number,
                  };
                }),
              };
            });
        })
      );
  }

  private getContentByKey(
    key: string,
    queryParam?: { [key: string]: string | number }
  ): Observable<IProductBySeoUrl | ICatalogBySeoUrl | IPageBySeoUrl | undefined> {
    return this.getContent(key, queryParam, !!queryParam).pipe(
      map(res => {
        if (!res) {
          return res;
        }
        if (res.type === 'catalog') {
          return {
            type: res.type,
            id: '',
            title: res.content.title,
            description: res.content.description,
            description1: res.content.description1,
            description2: res.content.description2,
            seourl: res.seourl,
            url: addLanguageKeyInUrl(res.seourl, this.translateService.currentLang, this.translateService.getLangs()),
            assets: res.content.imageUrl
              ? [
                  {
                    type: 'image' as const,
                    alt: res.content.title,
                    link: res.content.imageUrl,
                  },
                ]
              : [],
            hasFilter: !!res.content.filter,
            breadcrumbs: (res.content.breadcrumb || []).map(b => {
              return {
                title: b.title,
                url: addLanguageKeyInUrl(b.seourl, this.translateService.currentLang, this.translateService.getLangs()),
              };
            }),
            items: res.content.items.map(i => {
              if ('productNumber' in i && i.productNumber) {
                return {
                  type: 'product' as const,
                  id: `${i.product_id}`,
                  title: i.label,
                  subTitle: i.subLabel,
                  description: i.description,
                  seourl: i.seourl,
                  url: addLanguageKeyInUrl(
                    i.seourl,
                    this.translateService.currentLang,
                    this.translateService.getLangs()
                  ),
                  assets: i.imageUrl
                    ? [
                        {
                          type: 'image' as const,
                          alt: i.label,
                          link: i.imageUrl,
                        },
                      ]
                    : [],
                  minimumOrderQuantityText: i.minimumOrderQuantityText,
                  productNumber: i.productNumber,
                  inventoryStatus: i.inventory.inventoryStatus,
                  price: i.customerPrice?.salesPrice || 0,
                  pricePerUnitOfMeasurement: i.customerPrice?.unitYourPrice || 0,
                  listPrice: i.customerPrice?.listPrice || 0,
                };
              }
              return {
                type: 'catalog' as const,
                title: i.label,
                seourl: i.seourl,
                description: i.description,
                url: addLanguageKeyInUrl(i.seourl, this.translateService.currentLang, this.translateService.getLangs()),

                assets: i.imageUrl
                  ? [
                      {
                        type: 'image' as const,
                        alt: i.label,
                        link: i.imageUrl,
                      },
                    ]
                  : [],
              };
            }),
            totalRecords: res.totalRowCount,
          };
        }
        if (res.type === 'product') {
          return {
            type: res.type,
            breadcrumbs:
              res.content.breadcrumb?.map(b => {
                return {
                  title: b.title,
                  url: addLanguageKeyInUrl(
                    b.seourl,
                    this.translateService.currentLang,
                    this.translateService.getLangs()
                  ),
                };
              }) || [],
            id: `${res.content.product_id}`,
            title: res.content.title,
            subTitle: res.content.subTitle,
            description: res.content.description,
            seourl: res.seourl,
            url: addLanguageKeyInUrl(res.seourl, this.translateService.currentLang, this.translateService.getLangs()),
            productNumber: res.content.productnumber,
            minimumOrderQuantityText: res.content.minimumOrderQuantityText,
            price: res.content.customerPrice?.salesPrice,
            pricePerUnitOfMeasurement: res.content.customerPrice?.unitYourPrice,
            listPrice: res.content.customerPrice?.listPrice,
            lineTax: res.content.customerPrice?.lineTax,
            unitTax: res.content.customerPrice?.unitTax,
            standardCustomerPrice: res.content.customerPrice?.standardCustomerPrice,
            inventoryStatus: res.content.inventory.inventoryStatus,
            unitOfMeasurement: res.content.uom,
            productType: res.content.productType,
            width: res.content.width,
            weight: res.content.weight,
            orderUnitOfMeasurement: res.content.productOrderType,
            documents: (res.content.documents || []).map(d => ({
              title: d.title,
              url: `${environment.apiDomain}${d.url}`,
            })),
            quantityDiscountTable: res.content.quantityDiscountTable,
            properties: res.content.properties,
            assets: [
              ...(res.content?.images
                ?.filter(image => image.type === 'image')
                .map(image => ({
                  link: image.url,
                  type: 'image' as const,
                  alt: image.title,
                })) || []),
              ...(res.content.youtube_links
                ? res.content.youtube_links
                    .trim()
                    .split(',')
                    .map(link => {
                      return {
                        type: 'youtube' as const,
                        link: link.trim(),
                      };
                    })
                : []),
            ],
            inventoryDeliveryText: res.content.inventory.inventoryDeliveryText,
          };
        }
        if (res.type === 'page') {
          return {
            type: res.type,
            sections: (res.content.items || [])
              .filter(
                item =>
                  item.contentType === 'banner' ||
                  item.contentType === 'widget' ||
                  item.contentType === 'carousel' ||
                  item.contentType === 'markdown' ||
                  item.contentType === 'banner2' ||
                  item.contentType === 'duel-panel' ||
                  item.contentType === 'old-price-sheet'
              )
              .map(item => {
                if (item.contentType === 'banner') {
                  return {
                    type: item.contentType,
                    data: {
                      header: item.header,
                      description: item.description,
                      useMaxWidth: item.useMaxWidth,
                      backgroundImage: item.backgroundImage,
                      link: item.link
                        ? {
                            label: item.link.label,
                            url: addLanguageKeyInUrl(
                              item.link.url,
                              this.translateService.currentLang,
                              this.translateService.getLangs()
                            ),
                          }
                        : item.link,
                    },
                  };
                }
                if (item.contentType === 'banner2') {
                  return {
                    type: item.contentType,
                    data: {
                      header: item.header,
                      description: item.description,
                      useMaxWidth: item.useMaxWidth,
                      backgroundImage: item.backgroundImage,
                    },
                  };
                }
                if (item.contentType === 'duel-panel') {
                  return {
                    type: item.contentType,
                    data: {
                      header: item.header,
                      paragraph: item.paragraph,
                      useMaxWidth: item.useMaxWidth,
                      link: item.link,
                      image: item.image,
                      direction: item.direction,
                    },
                  };
                }
                if (item.contentType === 'markdown') {
                  return {
                    type: item.contentType,
                    data: {
                      content: item.content,
                      useMaxWidth: item.useMaxWidth,
                    },
                  };
                }
                if (item.contentType === 'old-price-sheet') {
                  return {
                    type: item.contentType,
                    data: {
                      useMaxWidth: item.useMaxWidth,
                    },
                  };
                }
                if (item.contentType === 'carousel' && item.theme === 'banner') {
                  return {
                    type: item.contentType,
                    data: {
                      useMaxWidth: item.useMaxWidth,
                      theme: item.theme,
                      items: item.items.map(i => {
                        return {
                          ...i,
                          link: i.link
                            ? {
                                label: i.link.label,
                                url: addLanguageKeyInUrl(
                                  i.link.url,
                                  this.translateService.currentLang,
                                  this.translateService.getLangs()
                                ),
                              }
                            : i.link,
                        };
                      }),
                    },
                  };
                }
                if (item.contentType === 'carousel' && item.theme === 'logo-slider') {
                  return {
                    type: item.contentType,
                    data: {
                      useMaxWidth: item.useMaxWidth,
                      theme: item.theme,
                    },
                  };
                }
                if (item.contentType === 'carousel' && item.theme === 'usecases') {
                  return {
                    type: item.contentType,
                    data: {
                      useMaxWidth: item.useMaxWidth,
                      theme: item.theme,
                      title: item.title,
                      description: item.description,
                      items: item.items.map(i => {
                        return {
                          ...i,
                          link: i.link
                            ? addLanguageKeyInUrl(
                                i.link,
                                this.translateService.currentLang,
                                this.translateService.getLangs()
                              )
                            : i.link,
                        };
                      }),
                    },
                  };
                }
                return {
                  type: item.contentType,
                  data: {
                    ...item.data,
                    useMaxWidth: item.data.useMaxWidth,
                  },
                };
              }),
          };
        }
        console.error(`Unknown type`);
        return undefined;
      })
    );
  }
  public getPageByKey(key: string): Observable<IPageBySeoUrl | undefined> {
    return this.getContentByKey(key).pipe(
      map(res => {
        if (!res) {
          console.error(`Requested page with key: ${key} doesn't exists`);
          return undefined;
        }

        if (res.type !== 'page') {
          console.error(`Requested page with key: ${key} is not a page but was of type: ${res.type}`);
          return undefined;
        }

        return res;
      })
    );
  }
  public getCatalogByKey(
    key: string,
    filter?: { [key: string]: string | number }
  ): Observable<ICatalogBySeoUrl | undefined> {
    return this.getContentByKey(key, filter).pipe(
      map(res => {
        if (!res) {
          console.error(`Requested catalog with key: ${key} doesn't exists`);
          return undefined;
        }

        if (res.type !== 'catalog') {
          console.error(`Requested catalog with key: ${key} is not a catalog but was of type: ${res.type}`);
          return undefined;
        }

        return res;
      })
    );
  }

  public getCatalogByKeyWithoutItems(key: string): Observable<ICatalogMini | undefined> {
    return this._getContentMetaByKey(key).pipe(
      map(res => {
        if (res?.type === 'catalog') {
          return {
            seourl: res.seourl,
            url: addLanguageKeyInUrl(res.seourl, this.translateService.currentLang, this.translateService.getLangs()),
            title: res.content.title,
            description: res.content.description,
            assets: res.content.imageUrl
              ? [
                  {
                    type: 'image' as const,
                    alt: res.content.title,
                    link: res.content.imageUrl,
                  },
                ]
              : [],
          };
        }

        console.error(`Unknown type`);
        return undefined;
      })
    );
  }
  public getCatalogByKeyIncludingFilter(
    key: string,
    filter?: { [key: string]: string | number }
  ): Observable<
    | (ICatalogBySeoUrl & {
        filters: ICatalogFilter[];
      })
    | undefined
  > {
    return this.getCatalogByKey(key, filter).pipe(
      switchMap(res => {
        if (!res) {
          return of(undefined);
        }
        if (res.hasFilter) {
          return this.getFilterByKey(key).pipe(
            map(filters => {
              return {
                ...res,
                filters,
              };
            })
          );
        }
        return of({
          ...res,
          filters: [],
        });
      })
    );
  }
  public getProductByKey(key: string): Observable<IProductBySeoUrl | undefined> {
    return this.getContentByKey(key).pipe(
      map(res => {
        if (!res) {
          console.error(`Requested product with key: ${key} doesn't exists`);
          return undefined;
        }

        if (res.type !== 'product') {
          console.error(`Requested product with key: ${key} is not a product but was of type: ${res.type}`);
          return undefined;
        }

        return res;
      })
    );
  }
  public searchProductByText(searchText: string, page = 1, limit = 100) {
    return this.http
      .post<SearchProductResponse>(`@api/cms/prd/listProducts`, {
        limit,
        pageno: page,
        search: searchText,
      })
      .pipe(
        map(res => {
          return {
            label: res.label,
            breadcrumbs: (res.breadcrumb || []).map(b => {
              return {
                title: b.title,
                url: addLanguageKeyInUrl(b.seourl, this.translateService.currentLang, this.translateService.getLangs()),
              };
            }),
            items: res.items.map<IProductMini>(i => {
              return {
                id: `${i.product_id}`,
                seourl: i.seourl,
                title: i.label,
                subTitle: i.subLabel,
                description: i.description,
                url: addLanguageKeyInUrl(i.seourl, this.translateService.currentLang, this.translateService.getLangs()),
                assets: i.imageUrl
                  ? [
                      {
                        type: 'image' as const,
                        alt: i.label,
                        link: i.imageUrl,
                      },
                    ]
                  : [],
                inventoryStatus: i.inventory.inventoryStatus,
                minimumOrderQuantityText: i.minimumOrderQuantityText,
                pricePerUnitOfMeasurement: i.customerPrice?.unitYourPrice || 0,
                productNumber: i.productNumber,
                price: i.customerPrice?.salesPrice || 0,
                listPrice: i.customerPrice?.listPrice || 0,
              };
            }),
            totalRecords: res.totalRowCount,
          };
        })
      );
  }

  public getRelatedProducts(key: string) {
    return this.http.get<RelatedProductsResponse[]>(`@api/cms/prd/listRelatedProductsBySeoUrl/${key}`).pipe(
      map(res => {
        return res.map<IProductMini>(product => {
          return {
            id: `${product.product_id}`,
            seourl: product.seourl,
            title: product.title,
            subTitle: product.subTitle,
            url: addLanguageKeyInUrl(
              product.seourl,
              this.translateService.currentLang,
              this.translateService.getLangs()
            ),
            assets: product.images?.map(image => {
              return {
                type: 'image' as const,
                alt: image.title,
                link: image.url,
              };
            }),
            inventoryStatus: product.inventory?.inventoryStatus,
            minimumOrderQuantityText: product.minimumOrderQuantityText,
            productNumber: product.productnumber,
            price: product.customerPrice?.salesPrice,
            pricePerUnitOfMeasurement: product.customerPrice?.unitYourPrice || 0,
            listPrice: product.customerPrice?.listPrice || 0,
          };
        });
      })
    );
  }
}
