import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, first, map, Observable, of, switchMap, tap } from 'rxjs';
import { IProductForBasketItem } from './icebreak-webshop.interface';
import { WebshopService } from './icebreak-webshop.service';
import { OrdersService } from './orders.service';

export interface IAddress {
  name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  postalCode?: string;
}

interface IBasketItem {
  id: string;
  quantity: number;
  price: number;
  unitYourPrice: number;
  unitNormalPrice: number;
  unitQuantity: number;
}

export interface IBasketItemWithProduct extends IBasketItem {
  product: IProductForBasketItem;
}
export interface IBasket {
  id: string;
  totalProductGross: number;
  totalProductNet: number;
  totalDiscount: number;
  totalFreight: number;
  totalVATBasis: number;
  totalVAT: number;
  totalTax: number;
  totalPayableAmount: number;
  deliverAtWarehouse: boolean;
  deliverAtMyCompanyAddress: boolean;
  requestForOffer: boolean;
  deliveryAddress?: IAddress;
  billingAddress?: IAddress;
  additionalInformation?: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    mark?: string;
    remark?: string;
  };
  items: IBasketItemWithProduct[];
}
interface IAddToBasketResponse {
  order_line_id: number;
  product: {
    title: string;
  };
}

export interface IBasketResponse {
  order_id: number;
  total_discount_amount: number;
  total_net_amount: number;
  total_freight_amount: number;
  total_vat_basis: number;
  total_vat: number;
  total_tax: number;
  total_total_order: number;
  delivery_address_name?: string;
  delivery_address_1?: string;
  delivery_address_2?: string;
  delivery_zipcode?: string;
  delivery_city?: string;
  billing_address_name?: string;
  billing_address_1?: string;
  billing_address_2?: string;
  billing_zipcode?: string;
  billing_city?: string;
  properties?: {
    deliverAtWarehouse?: boolean;
    deliverAtMyCompanyAddress?: boolean;
    additionalInformation?: {
      firstName?: string;
      lastName?: string;
      phone?: string;
      mark?: string;
      remark?: string;
    };
    offer?: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  private readonly http = inject(HttpClient);
  private readonly translateService = inject(TranslateService);
  private readonly webshopService = inject(WebshopService);
  private readonly orderService = inject(OrdersService);

  public readonly loading$ = new BehaviorSubject(false);
  public readonly basketLoaded$ = new BehaviorSubject(false);
  public readonly basket$ = new BehaviorSubject<IBasket | undefined>(undefined);

  public addProductToBasket(
    id: string,
    quantity: number,
    isSampleProduct = false
  ): Observable<{
    id: string;
    product: {
      title: string;
    };
  }> {
    return this.http
      .post<IAddToBasketResponse>(`@api/cms/ord/orderAddProduct/${id}`, {
        quantity,
        sample: isSampleProduct,
      })
      .pipe(
        map(res => {
          return {
            id: `${res.order_line_id}`,
            product: {
              title: res.product?.title || `${res.order_line_id}`,
            },
          };
        }),
        tap(() => {
          this.loadBasket().subscribe();
        })
      );
  }
  public removeProductFromBasket(lineId: string): Observable<void> {
    const currentBasket = this.basket$.getValue();
    if (currentBasket) {
      const currentItems = [...currentBasket.items];
      const index = currentItems.findIndex(i => i.id === lineId);
      if (index > -1) {
        currentItems.splice(index, 1);
        this.basket$.next({
          ...currentBasket,
          items: currentItems,
        });
      }
      return this.http.delete(`@api/cms/ord/orderline/${lineId}`).pipe(
        tap(() => {
          this.loadBasket().subscribe();
        }),
        map(() => undefined)
      );
    }
    return of();
  }

  public emptyBasketItems(): Observable<void> {
    const currentBasket = this.basket$.getValue();
    if (currentBasket) {
      this.basket$.next({
        ...currentBasket,
        items: [],
      });
      return this.http.get(`@api/cms/ord/basketEmpty`).pipe(
        tap(() => {
          this.loadBasket().subscribe();
        }),
        map(() => undefined)
      );
    }
    return of();
  }

  public updateLine(lineId: string, quantity: number) {
    return this.basket$.pipe(
      first(),
      switchMap(basket => {
        if (!basket) {
          throw `Basket unavailable`;
        }
        return this.http.put(`@api/cms/ord/orderline/${lineId}`, {
          order_id: basket.id,
          quantity,
        });
      }),
      tap(() => {
        this.loadBasket().subscribe();
      })
    );
  }

  public updateBasket(data: { offer: boolean }) {
    return this.http.post(`@api/cms/ord/updateOrder`, data).pipe(
      tap(() => {
        this.loadBasket().subscribe();
      })
    );
  }

  public updateBasketDeliveryAddress(data: {
    deliverAtWarehouse: boolean;
    deliverAtMyCompanyAddress: boolean;
    deliveryAddress?: {
      address?: string;
      city?: string;
      postalCode?: string;
    };
    additionalInformation?: {
      firstName?: string;
      lastName?: string;
      phone?: string;
      mark?: string;
      remark?: string;
    };
  }): Observable<void> {
    return this.http.post(`@api/cms/ord/upsertBasketDeliveryAddress`, data).pipe(map(x => undefined));
  }

  public loadBasket() {
    this.loading$.next(true);
    const currentBasket = this.basket$.getValue();
    return this.http.get<IBasketResponse>('@api/cms/ord/basket').pipe(
      map((basketResponse): IBasket => {
        return {
          id: `${basketResponse.order_id}`,
          totalProductGross: basketResponse.total_net_amount + basketResponse.total_discount_amount,
          totalProductNet: basketResponse.total_net_amount,
          totalDiscount: basketResponse.total_discount_amount,
          totalFreight: basketResponse.total_freight_amount,
          totalVATBasis: basketResponse.total_vat_basis,
          totalVAT: basketResponse.total_vat,
          totalTax: basketResponse.total_tax || 0,
          totalPayableAmount: basketResponse.total_total_order,
          deliverAtWarehouse: !!basketResponse.properties?.deliverAtWarehouse,
          deliverAtMyCompanyAddress: !!basketResponse.properties?.deliverAtMyCompanyAddress,
          requestForOffer: !!basketResponse.properties?.offer,
          deliveryAddress:
            basketResponse.delivery_address_name ||
            basketResponse.delivery_address_1 ||
            basketResponse.delivery_address_2 ||
            basketResponse.delivery_zipcode ||
            basketResponse.delivery_city
              ? {
                  name: basketResponse.delivery_address_name,
                  address1: basketResponse.delivery_address_1,
                  address2: basketResponse.delivery_address_2,
                  postalCode: basketResponse.delivery_zipcode,
                  city: basketResponse.delivery_city,
                }
              : undefined,
          billingAddress:
            basketResponse.billing_address_name ||
            basketResponse.billing_address_1 ||
            basketResponse.billing_zipcode ||
            basketResponse.billing_city
              ? {
                  name: basketResponse.billing_address_name,
                  address1: basketResponse.billing_address_1,
                  address2: basketResponse.billing_address_2,
                  postalCode: basketResponse.billing_zipcode,
                  city: basketResponse.billing_city,
                }
              : undefined,
          additionalInformation: basketResponse.properties?.additionalInformation,
          items: currentBasket?.items || [],
        };
      }),
      tap(basket => {
        this.basket$.next(basket);
      }),
      switchMap(basket => {
        return this.orderService.getOrderLines(basket.id).pipe(
          map(items => {
            return {
              ...basket,
              items,
            };
          })
        );
      }),
      tap(basket => {
        this.basket$.next(basket);
      }),
      tap(() => {
        this.basketLoaded$.next(true);
        this.loading$.next(false);
      })
    );
  }

  public loadBasketIfNotLoaded() {
    if (!this.loading$.getValue() && !this.basketLoaded$.getValue()) {
      this.loadBasket().subscribe();
    }
  }

  public finalizeBasket() {
    return this.http.post(`@api/cms/ord/completeOrder`, {}).pipe(
      tap(() => {
        this.loadBasket().subscribe();
      })
    );
  }
}
